import firebase from "./firebaseInit";

export const db = firebase.firestore()
export const firestore = firebase.firestore
export const FieldValue = firebase.firestore.FieldValue
export const personsRef = db.collection("Persons")
export const ordersRef = db.collection("Orders")
export const productsRef = db.collection("Products")
export const discountsRef = db.collection("Coupons")
export const stocksRef = db.collection("Stock")
export const carouselRef = db.collection("Carousel")
export const collectionsRef = db.collection("Collections")
export const categoriesRef = db.collection("categories")
export const productsBkpRef = db.collection("ProductsBkp")
export const searchRef = db.collection("Search")
export const jewelleryProductsRef = productsRef.where("type", "==", "jewellery")
// export const storageRef = firebase.storage();