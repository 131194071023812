<template>
	<div>
		<v-container>
			<v-row>
				<v-col md="6">
					<v-card>
						<v-list subheader two-line>
							<v-subheader inset>Coupons</v-subheader>

							<v-list-item v-for="coupon in coupons" :key="coupon.id">
								<v-list-item-avatar>
									<v-icon class="grey lighten-1" dark>
										mdi-sale
									</v-icon>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title v-text="coupon.code"></v-list-item-title>

									<v-list-item-subtitle v-text="formatTime(coupon.expiry)"></v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action>
									<v-btn @click="setCurrentCoupon(coupon.id)" icon>
										<!-- <v-btn @click="currentCouponId = coupon.id" icon> -->
										<v-icon color="primary">mdi-pencil</v-icon>
									</v-btn>
								</v-list-item-action>
								<v-list-item-action>
									<v-btn @click="deleteCoupon(coupon.id)" icon>
										<!-- <v-btn @click="currentCouponId = coupon.id" icon> -->
										<v-icon color="primary">mdi-delete</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</v-card>
				</v-col>
				<v-col md="6">
					<h3 class="title mb-4">Create a new coupon</h3>
					<v-btn @click="setCurrentCoupon(null)" class="primary" depressed>CREATE NOW</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog persistent v-model="showCouponDialog" width="480px">
			<v-card class="pa-4" outlined>
				<v-form ref="collectionForm" @submit.prevent="">
					<v-card-text>
						<v-text-field v-model="currentCoupon.code" placeholder="6 digit alphanumeric code" label="Coupon Code" />
						<v-text-field v-model.number="currentCoupon.rate" placeholder="Number between 0-100" label="Rate" />
						<v-text-field v-model.number="currentCoupon.maxDiscount" label="Max Discount" />
						<v-text-field v-model.number="currentCoupon.minAmount" label="Min Amount" />
						<v-row align="center">
							<v-col sm="4">
								<v-checkbox label="Limited Period" v-model="isLimitedPeriod" />
							</v-col>
							<v-col cols="12" sm="8">
								<v-menu ref="menu" v-model="menu" :close-on-content-click="false"
									:return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="currentCoupon.expiry" label="Expires On"
											:disabled="!isLimitedPeriod" prepend-icon="mdi-calendar" readonly
											v-bind="attrs" hide-details v-on="on"></v-text-field>
									</template>
									<v-date-picker color="primary" v-model="currentCoupon.expiry" no-title scrollable>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menu = false">
											Cancel
										</v-btn>
										<v-btn text color="primary" @click="$refs.menu.save(date)">
											OK
										</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end" v-if="true">
						<v-btn text @click="showCouponDialog = false">
							CANCEL
						</v-btn>
						<v-btn @click="createDiscountCoupon" color="primary" type="submit" :loading="false"
							depressed>DONE</v-btn>
					</v-card-actions>
					<v-card-actions v-else>
						<v-btn depressed block color="primary" @click="" v-if="false"><v-icon left>mdi-pencil</v-icon>
							EDIT
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
import { getDiscountsList, updateDiscount, addDiscount, removeDiscount } from './../api/discounts'
import { format, parse } from 'fecha';
import _ from 'lodash'

export default {
	data: () => ({
		coupons: [],
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		menu: false,
		isLimitedPeriod: false,
		// currentCouponId: null
		currentCoupon: {},
		showCouponDialog: false,
		isEditMode: false,
	}),
	computed: {
	},
	methods: {
		async createDiscountCoupon() {
			try {				
				if(this.isEditMode) {
					await updateDiscount(this.currentCoupon.id, { ...this.currentCoupon, expiry: this.parseDate(this.currentCoupon.expiry) })
					this.$setSnackbar("Updated")
					// console.log("Updated")
				} else {
					await addDiscount({ ...this.currentCoupon, expiry: this.parseDate(this.currentCoupon.expiry) })
					this.$setSnackbar("Coupon Successfully Added")					
					console.log("added")
				}
				this.fetchDiscounts()
			} catch (error) {
				console.log(error)
			}
			this.showCouponDialog = false
		},
		async deleteCoupon(docId) {
			try {
				await removeDiscount(docId)
				this.fetchDiscounts()
				this.$setSnackbar("Coupon Deleted Successfully")
			} catch (error) {
				console.log(error)
				this.$setSnackbar("Error deleting")
			}
		},
		fetchDiscounts() {
			getDiscountsList()
				.then(res => {
					this.coupons = res
				})
				.catch(e => {
					console.log(e)
				})
		},
		setCurrentCoupon(id) {
			if(id == null) {
				this.isEditMode = false				
				this.setBlankCoupon()
				// this.$forceUpdate()
			} else {
				this.isEditMode = true
				this.currentCoupon = _.cloneDeep(this.coupons.find(item => item.id == id))
				if(this.currentCoupon.expiry) {
					this.isLimitedPeriod = true
					this.currentCoupon.expiry = this.currentCoupon.expiry.toISOString().substr(0, 10)
				}
			}
			this.showCouponDialog = true
		},
		formatTime(date) {
			try {
				var date = format(date, 'mediumDate');
				return date
			} catch (e) {
				console.log(e)
				return null
			}
		},
		parseDate(dateString) {
			return parse(dateString, 'YYYY-MM-DD')
		},
		setBlankCoupon() {
			this.currentCoupon = {
				code: "",
				maxDiscount: null,
				minAmount: null,
				public: true,
				rate: null,
				expiry: this.date
			}
		}
	},
	mounted() {
		// this.setBlankCoupon()
		this.fetchDiscounts()
	}
}

</script>