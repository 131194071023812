import { doc, collection, getDoc, orderBy, query, updateDoc, getDocs, deleteDoc, setDoc, serverTimestamp } from 'firebase/firestore'
import { db, discountsRef, productsRef, firestore } from '../services/Database'

// const discountsRef = collection(db, "Clients", "MOH", "Discounts")
// const discountsRef = db.collection('catgories')

export async function getDiscountsList() {

    let res = []
    // return discountsRef.orderBy('timestamp', 'asc').get()
    return discountsRef.get()
      .then(snaps => {
        console.log(snaps.docs.length)
        snaps.forEach(snap => {
            let expiry = snap.data().expiry.toDate()
            res.push({ ...snap.data(), expiry })
        })
        return res
      })
    
    
}

export function setCollectionsItem(id, data) {
    return id == null? addDiscount(data) : updateDiscount(id, data)
}

export async function getDiscount(id) {
    let ref = discountRef(id)
    let snap = await getDoc(ref)
    return snap.data()
}

// export function addDiscount(data) {
//     let docId = discountRef.doc().id
//     console.log(docId)
//     return Promise.resolve()
//     return setDoc(doc(discountsRef, docId), { ...data, timestamp: Date.now(), id: docId })
// }

export function addDiscount(data) {
    if(!data) return
    let docId = discountsRef.doc().id
    
    return discountsRef
      .doc(docId)
      .set({
        ...data,
        id: docId,
        expiry: firestore.Timestamp.fromDate(data.expiry)
      })              
}

export function updateDiscount(docId, data) {

    return discountsRef
      .doc(docId)
      .set({
        ...data,
        expiry: firestore.Timestamp.fromDate(data.expiry)
    })
}

export function removeDiscount(docId) {
    return discountsRef
      .doc(docId)
      .delete()
}

// export function removeDiscount(id) {
//     let ref = discountRef(id)
//     return deleteDoc(ref)
// }

function discountRef(id) {
    return doc(db, "Clients/MOH/Discounts", id)
}