<template>
  <div class="clothes">
    <!-- <v-btn @click="syncSearch()">Sync</v-btn> -->
    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
      <v-row>
        <v-col cols="12">
          <!-- {{ filteredProducts.map(el => ({ id: el.id, name: el.name, imgs: el.images, desc: el.description })).filter(item => !!item && !!item.category !== 'Accessories' && !!item.desc) }} -->
          <!-- {{ filteredProducts.map(el => ({ prod_id: el.id,  prod_desc: el.description, prod_image: el.images[0] })).filter(item => !!item && !!item.prod_desc && !!item.category !== 'Accessories') }} -->
          <v-banner
            v-if="!!selectedItems.length"
            :sticky="true"
            color="white"
            class="mb-4"
            elevation="4"
            single-line
            rounded
          >
            {{ selectedItems.length }} items selected

            <template v-slot:actions>
              <v-btn
                fab
                small
                depressed
                outlined
                color="primary"
                @click="updateSelectedItemsPublishState(true)"
              >
                <v-icon>mdi-publish</v-icon>
              </v-btn>
              <v-btn
                fab
                depressed
                color="primary"
                outlined
                @click="updateSelectedItemsPublishState(false)"
                small
              >
                <v-icon>mdi-publish-off</v-icon>
              </v-btn>
              <v-btn
                fab
                depressed
                color="primary"
                outlined
                small
                @click="deleteSelectedItems"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-banner>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <v-text-field
            outlined
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            placeholder="Start Typing.."
            single-line
            hide-details
            class="mb-5"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-select
            outlined
            label="Filter"
            :items="filterFields.map(item => item.name)"
            @change="onItemsFilterChange($event)"
            value="All"
          />
        </v-col>
            <!-- :items="filterFields.filter(item => item.hide == false).map(item => item.name)" -->
        <!-- <v-col md="3">
          <v-select
            outlined
            label="Collection"
            :items="collections.map(item => item.name)"
            :value="selectedCollection"
            @change="onCollectionOptionChange($event)"
          />
        </v-col> -->
      </v-row>
      <v-row class="mt-0">
        <v-col md="12" class="mt-2 pt-0">
          <template>
            <v-data-table
              dense
              show-select
              v-model="selectedItems"
              :headers="headers"
              :items="filteredProducts"
              :items-per-page="40"
              :footer-props="{ itemsPerPageOptions: [ 20, 40, -1 ] }"
              :search="search"
              class="elevation-1"
              style="margin-bottom: 75px"
              :loading="!productsLoaded"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.images="{ item }">
                <v-img
                  class="my-2"
                  :src="item.images[0]"
                  aspect-ratio="1"
                  width="70px"
                ></v-img>
              </template>
              <template v-slot:item.name="{ item }">
                <span :class="[ item.tags.includes('isFeatured')? 'font-weight-bold' : '']">{{ item.name }}</span>
              </template>              
              <template v-slot:item.createdAt="{ item }">
                <span>
                  {{ formatDate(item.createdAt) }}
                </span>
                <span>
                  {{ item.slug }}
                </span>
              </template>              
              <!-- <template v-slot:item.payment="{ item }">
                <v-chip :color="getColor(item.payment)" dark small>
                  {{ item.payment }}
                </v-chip>
              </template> -->
              <!-- <v-chip :color="item.isLive == true? 'success' : 'grey'" outlined small>
                  <span v-if="item.isLive == true">PUBLISHED</span>
                  <span v-else>DRAFT</span>
                </v-chip> -->
              <template v-slot:item.isLive="{ item }">
                <v-switch
                  @change="updateItemPublishState($event, item.id)"
                  v-model="item.isLive"
                ></v-switch>
              </template>
              <template v-slot:item.isInStock="{ item }">
                <v-checkbox v-model="item.isInStock"></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  outlined
                  color="primary"
                  dark
                  small
                  class="mr-2"
                  @click="editItem(item.id)"
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  dark
                  small
                  @click="deleteItem(item.id)"
                >
                  <v-icon small>mdi-trash-can</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-btn to="/add-product" fab dark large color="primary" fixed right bottom>
      <v-icon dark>mdi-plus</v-icon>
    </v-btn>

    <v-dialog max-width="400" v-model="showAlertDialog">
      <v-card class="pb-2">
        <v-card-title> Sure to {{ selectedAction }}? </v-card-title>
        <v-card-text> This action is permanent </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showAlertDialog = false" outlined
            >CANCEL</v-btn
          >
          <v-btn @click="confirmSelectedItemsAction" color="primary"
            >CONFIRM</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>

import { format } from 'fecha'
import { removeProduct, updateProduct, addSearchItems } from "./../api/product";
import { getCollections } from './../api/collections';
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      search: "",
      allproducts: [],
      selectedItems: [],
      showAlertDialog: false,
      headers: [
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "images",
        },
        { text: "Product Name", value: "name" },
        // { text: "Collection", value: "category" },
        { text: "Price", value: "price[0]" },
        { text: "Collections", value: "collection" },
        { text: "Date Created", value: "createdAt" },
        { text: "Published", value: "isLive" },
        // { text: "In Stock", value: "isInStock" },
        // { text: "Tags", value: "tags" },
        { text: "Action", value: "actions", sortable: false },
      ],
      productsLoading: false,
      selectedAction: null,
      selectedCollection: '',
      filterFields: [
        {
          name: "All",
          hide: false,
          fn: (item) => true
        },
        {
          name: "Published",
          hide: false,
          fn: (item) => item['isLive'] == true
        },
        {
          name: "Unpublished",
          hide: false,
          fn: (item) => item['isLive'] == false
        },
        {
          name: "Featured",
          hide: false,
          fn: (item) => item.tags.includes('isFeatured')
        },
        {
          name: "Collection",
          hide: true,
          fn: {}
        }
      ],
      currentFilterIndex: 0,
      collections: []
    };
  },
  created() {
    // const collectionField = this.filterFields.find(item => item.name == "Collection")
  },
  computed: {
    filteredProducts() {
      return this.productsList
      .filter((item) => this.filterFields[this.currentFilterIndex].fn(item))      
    },
    ...mapGetters(["productsList", "productsLoaded"])
  },
  methods: {

    async syncSearch(){
      const product_name_arr = Array.from(new Set(this.productsList.map(item => item.name))) 
      // console.log(product_name_arr)
      try {
        await addSearchItems(product_name_arr)
        console.log('DONE!')
      } catch (error) {
        console.log('Update Error', error)
      }
    },

    getColor(payment) {
      if (payment.toLowerCase() == "paid") return "green";
      else return "red";
    },
    formatDate(timestamp) {
      // console.log(timestamp)
      // return timestamp
      return format(new Date(timestamp), 'DD.MM.YY | h:mma')
    },
    onItemsFilterChange(val) {
      this.currentFilterIndex = this.filterFields.findIndex(item => item.name == val)
      console.log(this.filterFields[this.currentFilterIndex])
    },
    onCollectionOptionChange(val) {
      this.selectedCollection = val
      this.onItemsFilterChange('Collection')
    },
    updateItemPublishState(val, itemId) {
      updateProduct({ isLive: val }, itemId)
        .then(() => this.$setSnackbar("Updated"))
        .catch((e) => console.log(e));
    },
    async updateSelectedItemsPublishState(val) {
      this.productsLoading = true;
      try {
        for (let item of this.selectedItems) {
          await updateProduct({ isLive: val }, item.id);
          item.isLive = val;
        }
        this.selectedItems = [];
        this.$setSnackbar("Updated");
      } catch (error) {
        console.log(error);
      }
      this.productsLoading = false;
    },
    async deleteItem(itemId) {
      this.productsLoading = true;
      await removeProduct(itemId);
      this.allproducts = this.allproducts.filter(
        (product) => product.id !== itemId
      );
      this.productsLoading = false;
    },
    async deleteSelectedItems() {
      this.selectedAction = "Delete";
    },
    async confirmSelectedItemsAction() {
      // TODO: ADD MORE ACTIONS
      this.productsLoading = true;
      try {
        for (let item of this.selectedItems) {
          await removeProduct(item.id)
          this.allproducts = this.allproducts.filter(
            (product) => product.id !== item.id
          );
        }
        this.selectedItems = []
        this.$setSnackbar("Deleted");
      } catch (error) {
        console.log(error);
      }
      this.showAlertDialog = false
      this.productsLoading = false;
    },
    editItem(itemId) {
      this.$router.push("/edit-product/" + itemId);
    },
  },
  mounted() {
    this.productsLoading = true;

    this.productsList
    
    // products()
    // .then((res) => {
    //   this.allproducts = res.sort((a,b) => a.price - b.price);
    //   this.$nextTick(() => (this.productsLoading = false));
    // });

    getCollections()
    .then(res => {
      this.collections = res
      const collectionField = this.filterFields.find(item => item.name == "Collection")
      this.selectedCollection = this.collections[0].name
      collectionField.fn = (item) => item.collections.includes(this.selectedCollection)
    })
  },
  watch: {
    selectedAction(val) {
      if (val !== null) this.showAlertDialog = true;
    },
    showAlertDialog(val) {
      if (val == false) this.selectedAction = null;
    },
  },
};
</script>